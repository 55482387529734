<template>
    <div v-if="hasResource('banner_add')" class="aops-banner-add">
        <div class="aop-back" @click="handleBack">
            <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
        </div>
        <div class="aop-add-title">{{id ? '编辑' : '新增'}}banner</div>
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="170px"
        >
            <el-form-item label="环境">
                <el-radio-group v-model="form.market">
                    <el-radio label="CN">国内</el-radio>
                    <el-radio label="US">海外</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                v-for="item in formItems"
                :key="item.prop"
                :label="item.label"
                :prop="item.prop"
                class="aop-form-item"
            >
                <el-input
                    v-model="form[item.prop]"
                    :placeholder="item.placeholder"
                />
            </el-form-item>
            <el-form-item label="跳转类型">
                <el-radio-group v-model="form.type">
                    <el-radio label="1">场景包
                        <el-form-item v-if="form.type === '1'" label="" prop="scene_pack_id">
                            <el-input
                                v-model="form.scene_pack_id"
                                placeholder="搜索场景包名称或ID"
                                readonly
                                @focus="handleShowSelectScene"
                            />
                            <div v-if="scene" class="aop-select-scene">
                                <span class="aop-select-scene-info">ID: {{scene.external_id}}
                                    &nbsp;&nbsp; 名称：{{scene.scene_pack_name}}</span>
                                <i class="iconfont aop-select-del" @click="handleDelScene">&#xe678;</i>
                            </div>

                        </el-form-item>
                    </el-radio>
                    <el-radio label="4">免费资产</el-radio>
                    <el-radio label="2">
                        url
                        <el-form-item v-if="form.type === '2'" label="" prop="link">
                            <el-input
                                v-model="form.link"
                                placeholder="请输入链接"
                            />
                        </el-form-item>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="banner图" prop="banner">
                <uploadImg
                    :config="uploadConfig['banner']"
                    :upload-image="form.banner"
                    @onChange="(img) => handleImgChange('banner', img)"
                />
            </el-form-item>
            <el-form-item label="发布时间" prop="publish_type">
                <el-radio-group v-model="form.publish_type">
                    <el-radio label="1">立即发布</el-radio>
                    <el-radio label="2">
                        定时发布
                        <el-form-item v-if="form.publish_type === '2'" label="" prop="publish_time">
                            <el-date-picker
                                v-model="form.publish_time"
                                value-format="timestamp"
                                type="datetime"
                                placeholder="请选择定时发布时间"
                            ></el-date-picker>
                        </el-form-item>
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="展示顺序" prop="list_order">
                <el-input v-model="form.list_order" class="aop-form-input"  />
                <div class="aop-label-tip">根据已有顺序，系统自动生成数字，默认生成在列表尾部，修改数字可调整顺序，数字相同会插入原本的位置。</div>
            </el-form-item>
        </el-form>
        <div class="aop-add-footer">
            <el-button type="primary" class="aop-confirm-button" :loading="btnLoading" @click="handleConfirm">保存</el-button>
            <el-button class="cancel-button" @click="handleBack">取消</el-button>
        </div>
        <selectScene ref="selectScene" @select="handleSelectScene" />
    </div>
</template>

<script>
import Api from '@/api/banner';
import uploadImg from '@/pages/_common/uploadImg/uploadImg';
import selectScene from './selectScene/selectScene.vue';
import { uploadConfig, initForm, formItems, rules } from './data';

export default {
    components: { uploadImg, selectScene },
    /**
     * data
     * @returns {*}
     */
    data() {
        return {
            formItems,
            uploadConfig,
            scene: null, // 关联场景包
            form: { ...initForm },
            rules,
            btnLoading: false
        };
    },
    computed: {
        /**
         * id
         * @return {string}
         */
        id() {
            return this.$route.query.id;
        }
    },
    /**
     * mounted
     */
    mounted() {
        if (this.id) {
            this.getDetail();
        }
    },
    methods: {
        /**
         * 获取详情
         */
        getDetail () {
            Api.bannerDetail({ banner_id: this.id }).then((res) => {
                if (res.data.code === 10200) {
                    const data = res.data.data || {};
                    this.form = data;
                    this.form.scene_pack_id = (data.scenePack && data.scenePack.external_id) || '';
                    this.scene = data.scenePack || null;
                    this.form.publish_time = data.publish_time ? data.publish_time * 1000 : '';
                } else {
                    this.$$error(res.data.message);
                }
            }).catch(() => {});
        },
        /**
         * 返回
        */
        handleBack() {
            this.$router.push('/operations/banner');
        },
        /**
         * 处理图片/视频，更改form
         * @param {*} prop {prop}
         * @param {*} img 添加{type, url} / 删除{oper, index}
         */
        handleImgChange(prop, img) {
            if (img.oper && img.oper === 'del') {
                this.form[prop].splice(img.index, 1);
                return;
            }
            const config = uploadConfig[prop];
            const data = {
                [`${img.type === 1 ? 'image' : 'video'}_url`]: img.url,
                type: img.type
            };
            if (config.limit === 1) {
                this.form[prop] = data;
            } else {
                this.form[prop].push(data);
            }
            this.$refs.form.validateField([prop]);
        },
        /**
         * 点击确认按钮
        */
        handleConfirm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    Api.bannerSave({
                        ...this.form,
                        publish_time: this.form.publish_type === '1' ? '' : this.form.publish_time / 1000
                    }).then((res) => {
                        if (res.data.code === 10200) {
                            this.$$success('banner保存成功');
                            this.handleBack();
                        } else {
                            this.$$error(res.data.message);
                        }
                        this.btnLoading = false;
                    }).catch((error) => {
                        this.$$error(error.message);
                        this.btnLoading = false;
                    });
                }
            });
        },
        /**
         * 打开场景包 选择框
        */
        handleShowSelectScene() {
            this.$refs.selectScene.dialogVisible = true;
        },
        /**
         * 选择场景包
         * @param {*} data {data}
        */
        handleSelectScene(data) {
            this.form.scene_pack_id = data.external_id;
            // this.form.banner = data.banner;
            this.scene = data;
            // this.$refs.form.validateField(['banner']);
        },
        /**
         * 删除关联场景包
         * @param {*} data {data}
        */
        handleDelScene() {
            this.form.scene_pack_id = '';
            this.scene = null;
        }
    }
};
</script>
<style lang="less">
@import "./index.less";
</style>
