export const uploadConfig = {
    banner: {
        type: ['image'],
        limit: 1,
        tip: '请上传图片比例为16:5；仅支持上传像素不低于1920*600的图片',
        width: 1920,
        height: 600,
        ratio: {
            width: 16,
            height: 5
        }
    }
};

export const formItems = [
    {
        label: 'banner名称',
        prop: 'banner_name',
        placeholder: '最多可输入20个字符，仅后台可见，供运营备注使用'
    },
    {
        label: 'banner主标题（中文）',
        prop: 'name',
        placeholder: '最多可输入50个字符，展示在中文版前端banner主标题位置'
    },
    {
        label: 'banner描述（中文）',
        prop: 'description',
        placeholder: '最多可输入200个字符，展示在中文版前端banner描述位置'
    },
    {
        label: 'banner主标题（英文）',
        prop: 'name_en',
        placeholder: '最多可输入100个字符，展示在英文版前端banner主标题位置'
    },
    {
        label: 'banner描述（英文）',
        prop: 'description_en',
        placeholder: '最多可输入400个字符，展示在英文版前端banner描述位置'
    }
];

export const initForm = {
    banner_name: '',
    market: 'CN',
    name: '',
    name_en: '',
    description: '',
    description_en: '',
    type: 3, // 1场景包,2url
    scene_pack_id: '', // 场景包id
    link: '',
    banner: null,
    publish_type: '1', // 发布方式,1:立即发布,2:定时发布
    publish_time: '',
    list_order: ''
};
/**
 * 顺序校验
 * @param {*} rule 规则
 * @param {*} value 值
 * @param {*} callback 返回
*/
const validateListOrder = (rule, value, callback) => {
    const reg = /^(\d)+$/;
    if (value && !reg.test(value)) {
        callback('只能输入数字，必须为整数');
    } else {
        callback();
    }
};
/**
 * 英文校验
 * @param {*} rule 规则
 * @param {*} value 值
 * @param {*} callback 返回
*/
const validateEn = (rule, value, callback) => {
    const reg = /[\u4E00-\u9FA5]/g;
    if (value && reg.test(value)) {
        callback('不能输入中文');
    } else {
        callback();
    }
};
export const rules = {
    banner_name: [
        { required: true, message: '请输入banner名称', trigger: 'blur' },
        { min: 1, max: 20, message: '最多可输入20个字符', trigger: 'blur' }
    ],
    name: [
        // { required: true, message: '请输入banner主标题（中文）', trigger: 'blur' },
        { min: 0, max: 50, message: '最多可输入50个字符', trigger: 'blur' }
    ],
    description: [
        // { required: true, message: '请输入banner描述（中文）', trigger: 'blur' },
        { min: 0, max: 200, message: '最多可输入200个字符', trigger: 'blur' }
    ],
    name_en: [
        // { required: true, message: '请输入banner主标题（英文）', trigger: 'blur' },
        { validator: validateEn, trigger: 'blur' },
        { min: 0, max: 100, message: '最多可输入100个字符', trigger: 'blur' }
    ],
    description_en: [
        // { required: true, message: '请输入banner描述（英文）', trigger: 'blur' },
        { validator: validateEn, trigger: 'blur' },
        { min: 0, max: 400, message: '最多可输入400个字符', trigger: 'blur' }
    ],
    scene_pack_id: [
        { required: true, message: '请选择关联场景包', trigger: 'change' }
    ],
    link: [
        { required: true, message: '请输入链接', trigger: 'blur' }
    ],
    banner: [
        { required: true, message: '请上传banner图', trigger: 'change' }
    ],
    publish_type: [
        { required: true }
    ],
    publish_time: [
        { required: true, message: '请选择定时发布时间', trigger: 'change' }
    ],
    list_order: [
        { validator: validateListOrder, trigger: 'blur' }
    ]
};

export const column = [{
    label: '选择',
    prop: 'select'
}, {
    label: '场景包ID',
    prop: 'external_id'
}, {
    label: '场景包名称',
    prop: 'scene_pack_name',
    showOverflowTooltip: true
}, {
    label: '场景包渲染图',
    prop: 'thumbnail'
}];
