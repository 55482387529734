<template>
    <div class="aops-upload-item">
        <img v-if="img.type === 1" class="aop-item-img" :src="img.image_url"  alt=""/>
        <video v-else :src="img.video_url" controls class="aop-item-img">
            <track default kind="captions" />
        </video>
    </div>
</template>

<script>

export default {
    props: {
        img: { // 单个图片
            type: Object,
            default: () => ({})
        }
    }
};
</script>
