<template>
    <!-- 停用 启用 -->
    <el-dialog
        top="12%"
        title="关联场景包"
        :visible="dialogVisible"
        width="840px"
        append-to-body
        class="pop-dialog aops-select-scene-dialog"
        @close="hide"
    >
        <div class="aop-scene-search search-form">
            <el-form ref="form" :inline="true" :model="form">
                <el-form-item label="场景包ID">
                    <el-input
                        v-model="form.external_id"
                        placeholder="请输入场景包ID"
                    ></el-input>
                </el-form-item>
                <el-form-item label="场景包名称">
                    <el-input
                        v-model="form.scene_pack_name"
                        placeholder="请输入场景包名称"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="text-btn">
                <span class="serach-button" @click="getSceneList">查询</span>
                <span class="clear-button" @click="handleReset">清空</span>
            </div>
        </div>
        <el-table
            v-loading="tableLoading"
            :data="listData"
            style="width: 100%;"
            header-cell-class-name="table-head"
            cell-class-name="table-cell"
            class="data-table"
            height="500px"
        >
            <el-table-column
                v-for="col in column"
                :key="col.prop"
                :label="col.label"
                :prop="col.prop"
                :min-width="col.minWidth"
                :show-overflow-tooltip="col.showOverflowTooltip"
                :fixed="col.fixed"
            >
                <template slot-scope="{$index, row}">
                    <div v-if="col.prop === 'select'">
                        <el-checkbox v-model="row.checked" @change="(val) => handleCheck(val, $index)"></el-checkbox>
                    </div>
                    <div v-else-if="col.prop === 'thumbnail'">
                        <img class="aop-scene-img" :src="row.thumbnail" alt=""  />
                    </div>
                    <div v-else>{{row[col.prop] || '--'}}</div>
                </template>
            </el-table-column>
        </el-table>
        <span slot="footer">
            <el-button
                type="primary"
                class="confirm-button"
                :disabled="!select"
                @click="handleConfirm"
            >确认</el-button>
            <el-button class="cancel-button" @click="hide">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import Api from '@/api/scene';
import { column } from '../data';

export default {
    props: {
        // id: { // 关联的场景id
        //     type: String,
        //     default: ''
        // }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            select: null, // 选中的
            dialogVisible: false,
            form: {
                external_id: '', // 场景包id
                scene_pack_name: ''
            },
            column,
            listData: [],
            tableLoading: false
        };
    },
    methods: {
        /**
         * 获取场景包列表
         */
        getSceneList() {
            this.tableLoading = true;
            Api.sceneList({
                limit: 1000,
                filter: {
                    scene_pack_status: [1, 3],
                    ...this.form
                }
            }).then((res) => {
                if (res.data.code === 10200) {
                    this.listData = res.data.data.items || [];
                } else {
                    this.$$error(res.data.message);
                }
                this.tableLoading = false;
            }).catch(() => {
                this.tableLoading = false;
                this.listData = [];
            });
        },
        /**
         * checkbox change
         * @param {*} val checkbox val
         * @param {*} index 场景包index
        */
        handleCheck(val, index) {
            if (val) {
                this.listData.forEach((item, i) => {
                    if (i === index) {
                        this.$set(item, 'checked', val);
                        this.select = item;
                    } else {
                        this.$set(item, 'checked', false);
                    }
                });
            } else {
                this.$set(this.listData[index], 'checked', val);
                this.select = null;
            }
        },
        /**
         * 点击确定按钮
        */
        handleConfirm() {
            this.$emit('select', this.select);
            this.hide();
        },
        /**
         * 清空
         */
        handleReset() {
            this.form = {
                external_id: '',
                scene_pack_name: ''
            };
        },
        /**
         * 关闭弹窗
        */
        hide() {
            this.dialogVisible = false;
            this.handleReset();
            this.listData = [];
        }
    }
};
</script>
<style lang="less">
@import "./selectScene.less";
</style>
