<template>
    <div class="aops-upload-img">
        <imgItem v-if="uploadImage" :img="uploadImage" />
        <div class="aop-upload-img-list">
            <div v-for="(img, index) in imgList" :key="index" class="aop-img-item">
                <imgItem :img="img" />
                <span class="aop-del-img" @click="handleDel(index)">
                    <i class="iconfont aop-del-icon">&#xe678;</i>
                </span>
            </div>
        </div>
        <el-upload
            :accept="accept"
            action="#"
            :auto-upload="false"
            :on-change="handleUpload"
            :show-file-list="false"
        >
            <el-button type="primary" :loading="loading" class="aop-confirm-button">
                {{uploadImage ? '重新上传' : '上传'}}
            </el-button>
            <span slot="tip" class="el-upload__tip">{{config.tip}}</span>
        </el-upload>
    </div>
</template>

<script>
import uploadImg from '@/mixins/upload-img';
import imgItem from './img.vue';

export default {
    components: { imgItem },
    mixins: [uploadImg],
    props: {
        config: {
            type: Object,
            default: () => ({
                type: ['image'],
                limit: 1,
                tip: '',
                width: null,
                height: null
            })
        },
        uploadImage: { // 单个上传
            type: Object || null,
            default: null
        },
        imgList: {
            type: Array,
            default: () => []
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            type: {
                image: '.jpg, .jpeg, .png, .apng, .gif, .bmp',
                video: '.mp4'
            },
            loading: false
            // curVideoUrl: ''
        };
    },
    computed: {
        /**
         * 可上传的类型
         * @returns {string}
         */
        accept() {
            const arr = this.config.type.map((item) => this.type[item]);
            return arr.join(', ');
        }
    },
    /**
     * mounted
     */
    mounted() {
        // const video = this.$refs.video;
        // video.addEventListener('canplay', (e) => {
        //     // console.log(e);
        //     // console.log(e.target.videoWidth);
        //     this.loading = false;
        //     this.handleVideo(e.target);
        //     // 视频上传
        // });
    },
    methods: {
        /**
         * 上传图片
         * @param {*} img img
         * @returns {*}
        */
        asyncImageOnload(img) {
            return new Promise((resolve) => {
                img.onload = () => {
                    resolve();
                };
            });
        },
        /**
         * 上传
         * @param {*} file file
         */
        async handleUpload(file) {
            if (this.config.limit > 1 && this.imgList.length === this.config.limit) {
                this.$$error(`最多可上传${this.config.limit}张`);
                return;
            }
            this.loading = true;
            const fileRaw = file.raw;
            // 文件类型 video/image
            const type = fileRaw.type.split('/')[0];
            const path = window.URL.createObjectURL(fileRaw);
            if (type === 'image') {
                this.handleImg(path, file);
            } else {
                // this.curVideoUrl = path;
                this.handleVideo(path);
            }
        },
        /**
         * 处理视频上传
         * @param {*} path path
         */
        handleVideo(path) {
            // 暂时视频功能不支持，后面再做
            this.loading = false;
            this.$emit('onChange', { type: 2, url: path });
        },
        /**
         * 处理图片上传
         * @param {*} path path
         * @param {*} file file
         */
        async handleImg (path, file) {
            const img = new Image();
            img.src = path;
            await this.asyncImageOnload(img);
            if (this.config.ratio && (img.width < this.config.width
            || img.height < this.config.height
            || (img.height % this.config.ratio.height) !== 0 || (img.width % this.config.ratio.width) !== 0)) {
                this.$$error(this.config.tip);
                this.loading = false;
                return;
            }
            if (this.config.width && (img.width < this.config.width
            || img.height < this.config.height
            || ((img.height / this.config.height) !== img.width / this.config.width))) {
                this.$$error(`请上传尺寸为${this.config.width}*${this.config.height}或其倍数的图片`);
                this.loading = false;
                return;
            }
            this.uploadImg(file);
        },
        /**
         * 上传图片
         * @param {File} file 图片
         */
        uploadImg(file) {
            try {
                this.handleUploadImg(file).then((res) => {
                    // if (res.data?.code === 10200 && res.data?.data?.url) {
                    //     this.$emit('onChange', { type: 1, url: res.data.data.url });
                    // } else if (res.data?.code === 10501) {
                    //     this.$$error('图片上传失败，请稍后重试或缩小图片大小');
                    // } else {
                    //     this.$$error('图片上传失败');
                    // }
                    this.$emit('onChange', { type: 1, url: res.url });
                    this.loading = false;
                }, () => {
                    this.loading = false;
                });
            } catch (error) {
                this.loading = false;
            }
        },
        /**
         * 删除图片
         * @param {number} index index
         */
        handleDel(index) {
            this.$emit('onChange', { oper: 'del', index });
        }
    }
};
</script>
<style lang="less">
@import "./uploadImg.less";
</style>
