import axios from '../uitls/require';

export default {
    /**
     * 场景包列表
     * @param {*} params 入参
     * @return {*}
     */
    sceneList(params) {
        return axios.post('/admin/scene-pack/list', params);
    },
    /**
     * 场景包更改状态
     * @param {*} params 入参
     * @return {*}
     */
    changeStatus(params) {
        return axios.post('/admin/scene-pack/change-status', params);
    },
    /**
     * 场景包编辑/新增
     * @param {*} params 入参
     * @return {*}
     */
    sceneSave(params) {
        return axios.post('/admin/scene-pack/save', params);
    },
    /**
     * 场景包详情
     * @param {*} params 入参
     * @return {*}
     */
    sceneDetail(params = {}) {
        return axios.get('/admin/scene-pack/detail', { params });
    }
};
